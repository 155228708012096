<template>
  <div class="product-list">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="列表"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="addObj">添加</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 产品列表 -->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="middle" justify="start">
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="search_op.search_name" @change="getList" clearable>
            <template slot="prepend">类型名</template>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="search_op.status" placeholder="请选择状态" @change="getList">
            <el-option
              v-for="item in status_arr"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-table
        :data="list"
        style="width: 100%">

        <el-table-column
          prop="name"
          width="200"
          label="类型名">
        </el-table-column>

        <el-table-column
          prop="time_limit"
          width="100"
          label="激活时长">
        </el-table-column>

        <el-table-column
        width="100"
          prop="allow_device_num"
          label="允许设备数">
        </el-table-column>

        <el-table-column
          width="100"
          prop="platform"
          label="激活平台">
        </el-table-column>

        <el-table-column
          width="100"
          prop="one_device_price"
          label="单设备价格">
        </el-table-column>

        <el-table-column
          width="100"
          prop="price"
          label="套餐价格">
        </el-table-column>

        <el-table-column
          prop="status"
          label="状态"
          width="80"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.status==1">
              <el-tag type="info" style="margin-right:5px">启用</el-tag>
            </template>
            <template v-if="scope.row.status==0">
              <el-tag type="danger" style="margin-right:5px">关闭</el-tag>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="comment"
          label="备注">
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
// import csv from "@/lib/arrObjToCsv.js"
// import util from "@/lib/util.js"
// import fileThum from "@/components/FileThum.vue"
// const audio = new Audio();
export default {
    components:{
      Title,
      // fileThum
    },
    name:'soundsList',
    data(){
      return {
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
          {name:'所有',value:null},
        ],
        platform_arr:[
          {name:'win32',value:'win32'},
          {name:'darwin',value:'darwin'},
          {name:'所有',value:''},
        ],
        list:[],
        category_list:[],
        page_num:1,
        page_size:5,
        total:0,
        search_op:{},
      }
    },
    methods:{
			confirm(ob,callback){
				this.$confirm(ob.msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: !ob.type?'warning':ob.type
				}).then(() => {
					callback()
				}).catch((e) => {
					console.error(e)
					this.$message({
						type: 'info',
						message: '已取消'
					});          
				});
			},
      getList(toS=true){
        if(toS){
          this.page_num = 1;
        }
        let op = {
          page_num:this.page_num,
          page_size:this.page_size
        }
        Object.assign(op,this.search_op)
        apis.code_type.read(op).then(res=>{
          console.log('res',res)
          this.list = (res.data.data.list || [])
          this.total = res.data.data.count
        }).catch(err=>{
          console.log('err',err)
        })
      },
      handleEdit(index, row) {
        this.$router.push({name:'updateCodeType',params:{obj:row}})
        console.log(index, row);
      },
      handleDelete(index, row) {
				let callback = ()=>{
          let op = {id:row.id}
          apis.sounds.remove(op).then(res=>{
            console.log('res',res)
          }).catch(err=>{
            console.log('err',err)
          })
				}
				this.confirm({msg:`确认使用删除当前音效[${row.name}]？`},callback)
      },
      handleSizeChange(val) {
        this.page_size = val
        this.getList(false)
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page_num = val
        this.getList(false)
        console.log(`当前页: ${val}`);
      },
      addObj(){
        this.$router.push({path:'/codetypes/add'})
      }
    },
    activated(){
      this.getList(false)
    },
    mounted(){
      this.getList()
    }
}
</script>

<style lang="scss" scoped>
.product-list{
  padding:15px;
}

</style>